﻿export const throttle = <T extends []>(callback: (..._: T) => void, wait: number): ((..._: T) => void) => {
  let queuedToRun: NodeJS.Timeout | undefined;
  let previouslyRun: number;
  return function invokeFn(...args: T) {
    const now = Date.now();
    queuedToRun = clearTimeout(queuedToRun) as undefined;
    if (!previouslyRun || now - previouslyRun >= wait) {
      callback(...args);
      previouslyRun = now;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      queuedToRun = setTimeout(invokeFn.bind(null, ...args), wait - (now - previouslyRun));
    }
  };
};

type Process = (...args: unknown[]) => void;
export const debounce = (callback: (args: unknown[]) => void, ms: number): Process => {
  let timeout: NodeJS.Timeout;
  return (...args: unknown[]): void => {
    clearTimeout(timeout);
    timeout = setTimeout((): void => callback(args), ms);
  };
};

export const isEmptyString = (str: string | undefined): boolean => {
  return !str || 0 === str.length || str.trim().length === 0;
};

export enum DeviceType {
  Mobile,
  Tablet,
  Desktop,
}

export function getDeviceType(): DeviceType {
  const windowWidth = document.documentElement.clientWidth;

  if (windowWidth < 448) {
    return DeviceType.Mobile;
  }
  if (windowWidth < 770) {
    return DeviceType.Tablet;
  }
  return DeviceType.Desktop;
}

export function clearClassList(arrow: Element): void {
  const classList = arrow.classList;
  for (let i = classList.length; i > 0; i--) {
    classList.remove(classList[i]);
  }
}

export interface OutOfViewInfo {
  top: boolean;
  left: boolean;
  bottom: boolean;
  right: boolean;
  any: boolean;
}

export function isOutOfViewport(el: Element | DOMRect, deviationY = 0, deviationX = 0): OutOfViewInfo {
  // Get element's bounding

  const bounding = el instanceof Element ? el.getBoundingClientRect() : el;

  const windowBottom = window.innerHeight || document.documentElement.clientHeight;

  // Check if it's out of the viewport on each side
  const top = bounding.top + deviationY < 0 || windowBottom < bounding.top + deviationY;
  const left = bounding.left + deviationX < 0;
  const bottom = bounding.bottom - deviationY > (window.innerHeight || document.documentElement.clientHeight);
  const right = bounding.right - deviationX > (window.innerWidth || document.documentElement.clientWidth);
  return { top, left, bottom, right, any: top || left || bottom || right };
}

export function GetYOutOfViewport(el: Element | DOMRect, margin = 0): number {
  // Get element's bounding

  const bounding = el instanceof Element ? el.getBoundingClientRect() : el;
  const windowBottom = window.innerHeight || document.documentElement.clientHeight;

  const outside = bounding.bottom > windowBottom ? bounding.bottom - windowBottom + margin : 0;

  return outside;
}

export function uuid(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function shortId(): string {
  return "xxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(8);
  });
}

export function getNumberAttribute(el: Element, attributeName: string): number {
  const attr = el.getAttribute(attributeName);
  const num = parseInt(attr, 10);
  if (isNaN(num)) {
    return 0;
  }
  return num;
}

export const toNumber = (value: string) => {
  const num = parseInt(value, 10);
  if (isNaN(num)) {
    return 0;
  }
  return num;
};

export const CopytoClipBoard = (elValue: string): void => {
  // Create new element
  elValue = decodeURIComponent(elValue);

  // Copy text to clipboard
  if (navigator.clipboard) {
    navigator.clipboard.writeText(elValue).catch((err) => console.error("Copy to clipboard failed", err));
  } else {
    const el = document.createElement("input");
    // Set value (string to be copied)
    el.value = elValue;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute("readonly", "");
    el.setAttribute("style", '{ position: "absolute", left: "-9999px" }');
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // use old commandExec() way / Depricated
    document.execCommand("copy");
    // Remove temporary element
    document.body.removeChild(el);
  }
};

export const trimChars = (str: string, chars: string): string => {
  return str.replace(new RegExp(`^[${chars}]+|[${chars}]+$`, "g"), "");
};
export const trimUrlParam = (url: string): string => {
  return trimChars(url.split("?")[0], "?");
};

export const findFirst = (arr: [], func: (value: never, index: number, array: unknown[]) => []): unknown[] => {
  const res = arr.filter(func);
  if (res) return res;
  return new Array<unknown>();
};

export const isInteger = (value: number): boolean => {
  return value % 1 === 0;
};

export const toBoolean = (value: unknown): boolean => {
  return value === "true" || value === "True" || value === true;
};

export function queryAll<T extends Element = Element>(selector: string, el = document): T[] {
  return Array.from(el.querySelectorAll(selector));
}

export const getUrlParam = (paramName: string): string => {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  return searchParams.get(paramName);
};

export const padZeros = (num: number, maxLength: number): string => {
  return num.toString().padStart(maxLength, "0");
};

export const convertSecToTime = (sec: number): string => {
  if (!sec || Number.isNaN(sec)) {
    return "";
  }

  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec % 3600) / 60);
  const seconds = Math.floor((sec % 3600) % 60);

  const hoursString = hours > 0 ? `${hours}:` : "";
  const minuteString = hours > 0 ? padZeros(minutes, 2) : minutes.toString();
  const secondsString = seconds >= 0 ? padZeros(seconds, 2) : "";
  return `${hoursString}${minuteString}:${secondsString}`;
};
export type BusinessUnit = "B2B" | "B2C";

export const normalizeWhitespace = (text: string): string => text.trim().replace(/\n/g, " ").replace(/ +/g, " ");
